<template>
  <v-sheet class="transparent page-sheet">
    <v-row>
      <v-col cols="9">
        <v-btn fab color="pink" dark @click="(item_={},d_edit=true)">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="3">
        <v-text-field v-model="search" append-icon="mdi-magnify" clearable></v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="items"
      item-key="idx"
      :search="search"
      class="transparent table-custom"
      :footer-props="foot_props"
      :no-data-text="$t('label.nodata')"
    >
      <template #item.action="{item}">
        <v-btn icon @click="(item_={...item}, d_edit=true)" color="primary">
          <v-icon>mdi-pencil</v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="d_edit" persistent width="400">
      <v-card>
        <v-card-title>
          <i18n path="cmsuser.dlgttl">
            <template #idx>{{item_.idx}}</template>
          </i18n>
          <v-spacer></v-spacer>
          <v-btn icon color="error" @click="d_edit=false" dark>
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-text-field
              v-model="item_.username"
              :label="$t('cmsuser.user')"
              :rules="[$rules.required]"
            ></v-text-field>
            <v-select
              v-model="item_.priveleges"
              :items="$t('cmsuser.allpriveleges')"
              :label="$t('cmsuser.priveleges')"
              :rules="[$rules.required]"
              multiple
            ></v-select>
            <v-text-field
              v-model="item_.pass"
              :label="$t('cmsuser.password')"
              append-icon="mdi-dice-multiple"
              @click:append="generatePass"
              :error-messages="passError"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text color="success" @click="updateUser" :disabled="disabled">
            <v-icon>mdi-check</v-icon>
            <i18n path="btn.save" />
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-sheet>
</template>

<script>
import commonmixin from "@/mixins/commonlist.js";
import passUtils from "@/password.js";
const apiBase = "v1/cms/cmsusers";

export default {
  name: "CmsUsers",
  mixins: [commonmixin],
  computed: {},
  data() {
    return {
      d_edit: false,
      disabled: false,
      passError: "",
      item_: {},
      headers: [
        {
          text: "#",
          value: "idx",
        },
        {
          text: this.$t("cmsuser.user"),
          value: "username",
        },
        {
          text: this.$t("cmsuser.priveleges"),
          value: "priveleges",
        },
        {
          text: this.$t("fields.created"),
          value: "created",
        },
        {
          text: "",
          value: "action",
          width: "50",
        },
      ],
    };
  },
  methods: {
    // loadData() {
    //   this.$api
    //     .apiGetRequest(apiBase, {
    //       sort: this.sortByParam("created"),
    //       page: this.pagination.page - 1,
    //       size: this.pagination.itemsPerPage,
    //     })
    //     .then((response) => {
    //       if (response.content) this.items = [...response.content].sort();
    //       this.totalItems = response.totalElements;
    //     });
    // },
    generatePass() {
      this.$set(this.item_, "pass", passUtils.generate(8));
      console.log(this.item_);
    },
    loadData() {
      this.$api.apiGetRequest(apiBase).then((response) => {
        this.items = [...response];
      });
    },
    updateUser() {
      if (!this.$refs.form.validate()) return;
      if (!this.item_.idx && !this.item_.password) this.passError = "required";
      this.disabled = true;
      this.$api
        .apiPostRequest(apiBase, this.item_)
        .then((response) => {
          if (response.idx) {
            const ind = this.items.findIndex((e) => e.idx == response.idx);
            if (ind == -1) {
              this.items.push(response);
            } else {
              this.items.splice(ind, 1, response);
            }
          }
        })
        .finally(() => {
          this.disabled = true;
          this.d_edit = false;
        });
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>

<style scoped>
.dlgtext >>> input {
  text-align: right;
  font-size: 24px;
}
</style>